import { isValid, parseISO, formatDistance, format } from 'date-fns';

export const isValidEmail = (emailAddress) => {
	if(!emailAddress){
		return false;
	}
	const re = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
	return re.test(emailAddress);
};

export const isValidPhone = (phoneNumber) => {
	if(!phoneNumber){
		return false;
	}
	const re = /(^\+[1-9]\d{10,14}$)/;
	return re.test(phoneNumber);
};

export const isValidPassword = (password) => {
	if(!password){
		return false;
	}
	const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
	return re.test(password);
};

export const timeAgo = (timestamp = Date.now()) => {
	if(isValid(parseISO(timestamp))) {
		return formatDistance(parseISO(timestamp), Date.now(), { addSuffix: true });
	}else{
		return formatDistance(timestamp, Date.now(), { addSuffix: true });
	}
	
};

export const formatCurrency = (amount) => {
	const d = amount ? amount : 0;
	return d.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

const date = {
	DEFAULT: "yyyy-MM-dd",
	MEDIUM: "MMM dd yyyy",
	LONG: "MMMM dd, yyyy",
	SHORT: "MMMM dd",
};

export const formatDate = (timestamp = Date.now(), dateFormat = date.LONG) => {
	if(isValid(parseISO(timestamp))) {
		return format(parseISO(timestamp), dateFormat);
	}else{
		return format(timestamp, dateFormat);
	}
};

export const getAge = (year) => {
	console.log(year, parseInt(format(new Date(), "yyyy") - year));
	return parseInt(format(new Date(), "yyyy") - year);
};

/* export const collectionPath = (fsDoc) => {
	switch(true) {
	case fsDoc == "packages":
		return `${collections.BANDS}/${this.currentBand.id}/${subCollections.EVENTS}/${this.currentEvent.id}/${subCollections.EVENT_PACKAGES}`;
	case fsDoc == "addons":
		return `${collections.BANDS}/${this.currentBand.id}/${subCollections.EVENTS}/${this.currentEvent.id}/${subCollections.EVENT_ADDONS}`;
	default:
		return null;
	}
}, */